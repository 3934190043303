import { computed, Injectable } from '@angular/core';
import { FeatureFlagsService } from '@client-portal/services/feature-flags';
import { AllowedShiftBookingType } from '@client-portal/enums/user';

@Injectable({
  providedIn: 'root',
})
export class AllowedShiftTypeService {
  public advanceOnly = computed(
    () =>
      this._featureFlags.values.shiftBookingType() ===
      AllowedShiftBookingType.Advance,
  );
  public shortNoticeOnly = computed(
    () =>
      this._featureFlags.values.shiftBookingType() ===
      AllowedShiftBookingType.ShortNotice,
  );
  public shortNoticeOrAdvance = computed(
    () => this.advanceOnly() || this.shortNoticeOnly(),
  );

  constructor(private _featureFlags: FeatureFlagsService) {}
}
